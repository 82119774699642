import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from './i18n/translation.service';
import { locale as enLang } from './i18n/vocabs/en';
import { locale as thLang } from './i18n/vocabs/th';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  openBoxContact :boolean = false;
  constructor(
    private translationService: TranslationService,
    private translate: TranslateService) {

    this.translationService.loadTranslations(
      enLang,
      thLang,
    );
  }
  
  ngOnInit() { }
  ngAfterViewInit() {}
  ngOnDestroy() {}



    data_section_contact_us = {
      company: "AYODIA Co., Ltd.",
      stamp: "© 2021 AYODIA Co., Ltd.",
      companyLogo_Show: true,
      email: "contact@ayodiacompany.com",
      companyDetail: "A next-generation IT company that evolves your business by developing smarter system and providing trustworthy supports",
      tel: "02-862-3021",
      followUs_Show: true,
      followUs: {
        line: "",
        ig: "",
        facebook: ""
      }
    };

    data_section_law_other = [ {
      id:1,
      name:"พระราชบัญญัติความความปลอดภัย อาชีวอนามัย และสภาพแวดล้อมในการทำงาน พ.ศ. 2562",
      link:"http://www.ratchakitcha.soc.go.th/DATA/PDF/2562/A/067/T_0215.PDF?fbclid=IwAR2-7ueT8X3hltgukfPAAI7fMeLTRe1vcUxHAYi5uadR83f1thz4FciVwYg"
    },
    {
      id:6,
      name:"พระราชบัญญัติความความปลอดภัย อาชีวอนามัย และสภาพแวดล้อมในการทำงาน พ.ศ. 2554",
      link:"https://www.labour.go.th/index.php/labor-law/category/6-laws-labor-4?download=24:2019-07-12-02-19-43",
    },{
      id:3,
      name:"ระเบียบกรมสวัสดิการและคุ้มครองแรงงาน ว่าด้วยการตรวจ สถานประกอบกิจการตามพระราชบัญญัติความปลอดภัย อาชีวอนามัย และสภาพแวดล้อมในการทำงาน พ.ศ. 2554 พ.ศ. 2561",
      link:"https://www.labour.go.th/index.php/labor-law/category/6-laws-labor-4?download=25:2019-07-12-02-21-52",
    },
    {
      id:2,
      name:"กฎกระทรวง กำหนดมาตรฐานในการบริการ จัดการ และดำเนินการด้านความปลอดภัยอาชีวอนามัย และสภาพแวดล้อมในการทำงานเกี่ยวกับที่อับอากาศ พ.ศ. 2562",
      link:"https://www.labour.go.th/index.php/labor-law/category/6-laws-labor-4?download=28:2019-07-12-02-25-32",
    },{
      id:4,
      name:"กฎกระทรวง กำหนดมาตรฐานในการบริการ จัดการ และดำเนินการด้านความปลอดภัยอาชีวอนามัย และสภาพแวดล้อมในการทำงานเกี่ยวกับที่อับอากาศ พ.ศ. 2559",
      link:"https://www.labour.go.th/index.php/labor-law/category/6-laws-labor-4?download=29:2019-07-12-02-26-13",
    },{
      id:5,
      name:"กฎกระทรวงกำหนดมาตรฐานในการบริหาร จัดการ และดำเนินการด้านความปลอดภัย อาชีวอนามัย และสภาพแวดล้อมในการทำงานเกี่ยวกับสารเคมีอันตราย พ.ศ. 2556",
      link:"https://www.labour.go.th/index.php/labor-law/category/6-laws-labor-4?download=31:2019-07-12-02-27-31",
    }];
    data_section_company = [
      {
        id: 1,
        name: "Good practice guidance on occupational health risk assessment",
        link: "https://www.workplacesafetynorth.ca/sites/default/files/resources/Mining_health-and-safety_health-risk-assessment_2nd-edition-Intl_Council_Mining_Metals-2017.pdf",
      },
      {
        id: 2,
        name: "ISO 45001 มาตรฐานระบบการจัดการ อาชีวอนามัยและความปลอดภัย",
        link: "https://www.bsigroup.com/th-TH/occupational-health-and-safety-iso-45001/",

      },
      {
        id: 3,
        name: "สมาคมส่งเสริมความปลอดภัยและอนามัยในการทำงาน (ประเทศไทย) ในพระราชูปถัมภ์",
        link: "https://www.shawpat.or.th/",

      },
      {
        id: 4,
        name: "มูลนิธิสัมมาอาชีวะ",
        link: "https://www.summacheeva.org/main",

      },
      {
        id: 5,
        name: "กรมสวัสดิการและคุ้มครองแรงงาน",
        link: "https://www.labour.go.th/index.php/home",

      },
      {
        id: 6,
        name: "สมาคมอาชีวอนามัยและความปลอดภัยในการทำงาน",
        link: "https://www.ohswa.or.th/",
      },
    ];
    data_master = {
      text_contact_us: "Contact us",
      text_follow_us: "FOLLOW US",
      text_law_footer: "กฎหมายที่เกี่ยวข้อง",
      text_lawCompany_footer: "หน่วยงานที่เกี่ยวข้อง"
    }
}
