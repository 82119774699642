// USA
export const locale = {
  lang: 'EN',
  data: {
    PAGE_HOME : {
      OVERVIEW : {
        data_section_overview : {
          title: "HRA",
          detail: "N/A"
        }
      }
    }
  }
};
